<!--每日数据新-->
<template>
    <div class="dayData">
        <div class="wrapper">
            <div class="header-wrapper">
                <span>选择时间段</span>
                <el-date-picker
                    v-model="value1"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    class="time"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    :default-time="defaultTime"
                >
                </el-date-picker>
                <el-select
                    v-model="station"
                    placeholder="请选择"
                >
                    <el-option
                        v-for="item in stations"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
                <span class="search-btn" @click="search">查询</span>
            </div>
            <div class="main" v-loading="loading">
                <!-- 色块 -->
                <div class="left clearfix">
                    <div class="big block-item block1" @click="showDataInfo('mrsj_xsfl','销售方量')">
                        <p class="dgfont font80" :title="statistic.xsfl">
                            {{ statistic.xsfl }}
                        </p>
                        <p class="discribe font30">
                            销售方量
                        </p>
                    </div>
                </div>
                <div class="left clearfix">
                    <div class="big block-item block2" @click="dataDrilling('new_mrsj_fhfl','发货方量')">
                        <p class="dgfont font80" :title="statistic.fhfl">
                            {{ statistic.fhfl }}
                        </p>
                        <p class="discribe font30">
                            发货方量
                        </p>
                        <div class="child-item block-item block2-1" @click.stop="dataDrilling('new_mrsj_xscf','销售差方')">
                            <div class="bottom-position">
                                <p class="dgfont font40" :title="statistic.xscf">
                                    {{ statistic.xscf }}
                                </p>
                                <p class="discribe">
                                    销售差方
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <div class="row-wrpper">
                        <div class="item block-item block3" @click="showDataInfo('mrsj_ddpdfl','调度派单方量')">
                            <div class="bottom-position">
                                <p class="dgfont font60" :title="statistic.ddpdl">
                                    {{ statistic.ddpdl }}
                                </p>
                                <p class="discribe">
                                    调度派单量
                                </p>
                            </div>
                        </div>
                        <div class="item block-item block4" @click="showDataInfo('mrsj_scfl','生产方量')">
                            <div class="bottom-position">
                                <p class="dgfont font60" :title="statistic.scfl">
                                    {{ statistic.scfl }}
                                </p>
                                <p class="discribe">
                                    生产方量
                                </p>
                            </div>
                            <div class="child-item block-item block4-1" @click.stop="showDataInfo('mrsj_sccf','生产差方')">
                                <div class="bottom-position">
                                    <p class="dgfont font40" :title="statistic.sccf">
                                        {{ statistic.sccf }}
                                    </p>
                                    <p class="discribe">
                                        生产差方
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row-wrpper">
                        <div class="item block-item block5" @click="dataDrilling('mrsj_zffl','转发方量')">
                            <div class="bottom-position">
                                <p class="dgfont font60" :title="statistic.zffl">
                                    {{ statistic.zffl }}
                                </p>
                                <p class="discribe">
                                    转发方量
                                </p>
                            </div>
                        </div>
                        <div class="col-wrapper">
                            <div class="item block-item block6" @click="dataDrilling('mrsj_ttfl','剩退方量')">
                                <p class="discribe">
                                    剩退方量
                                </p>
                                <p class="dgfont font40" :title="statistic.ttfl">
                                    {{ statistic.ttfl }}
                                </p>
                            </div>

                            <div class="item block-item block7" @click="dataDrilling('mrsj_pyfl','盘盈方量')">
                                <div class="bottom-position">
                                    <p class="discribe">
                                        盘盈方量
                                    </p>
                                    <p class="dgfont font40" :title="statistic.pyfl">
                                        {{ statistic.pyfl }}
                                    </p>
                                </div>
                                <div class="child-item block-item block7-1" @click.stop="dataDrilling('mrsj_pkfl','盘亏方量')">
                                    <div class="bottom-position">
                                        <p class="dgfont font30" :title="statistic.pkfl">
                                            {{ statistic.pkfl }}
                                        </p>
                                        <p class="discribe">
                                            盘亏方量
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row-wrpper wrapper-small">
                        <div class="item block-item block8" @click="dataDrilling('mrsj_ddpdfl_qtfl','其他方量')">
                            <p class="discribe">
                                其他方量
                            </p>
                            <p class="dgfont font60" :title="statistic.qtfl">
                                {{ statistic.qtfl }}
                            </p>
                        </div>
                    </div>
                    <div class="row-wrpper">
                        <div class="item block-item block9" @click="dataDrilling('mrsj_ccgbl','出场过磅量')">
                            <div class="bottom-position">
                                <p class="dgfont font60" :title="statistic.ccgbl">
                                    {{ statistic.ccgbl }}
                                </p>
                                <p class="discribe">
                                    出场过磅量
                                </p>
                            </div>
                            <div class="child-item block-item block9-1" @click.stop="dataDrilling('mrsj_gbcf','过磅差方')">
                                <div class="bottom-position">
                                    <p class="dgfont font50" :title="statistic.gbcf">
                                        {{ statistic.gbcf }}
                                    </p>
                                    <p class="discribe">
                                        过磅差方
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row-wrpper">
                        <div class="item block-item block10" @click="dataDrilling('mrsj_khqsfl','客户签收方量')">
                            <div class="bottom-position">
                                <p class="dgfont font60" :title="statistic.khqsfl">
                                    {{ statistic.khqsfl }}
                                </p>
                                <p class="discribe">
                                    客户签收方量
                                </p>
                            </div>
                            <div class="child-item block-item block10-1" @click.stop="dataDrilling('mrsj_qscf','签收差方')">
                                <div class="bottom-position">
                                    <p class="dgfont font40" :title="statistic.qscf">
                                        {{ statistic.qscf }}
                                    </p>
                                    <p class="discribe">
                                        签收差方
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="item block-item block11" @click="dataDrilling('mrsj_sjxlfl','司机卸料方量')">
                            <div class="bottom-position">
                                <p class="dgfont font60" :title="statistic.sjqsfl">
                                    {{ statistic.sjqsfl }}
                                </p>
                                <p class="discribe">
                                    司机卸料方量
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import util from '@/utils/util';
export default {
    components: {},
    props: {},
    data() {
        return {
            loading: false,
            defaultTime: ['06:00:00', '06:00:00'],
            // 时间范围
            value1: [],
            // 选中场站
            station: '',
            // 场站列表
            stations: [],
            // 统计数
            statistic: {},
            // 图表数据
            chartData: {},
            legendData: [],
            saleData: [],
            deliverData: [],
        };
    },
    watch: {},
    computed: {},
    created() {},
    mounted() {
        this.init();
    },
    methods: {
        // 初始化
        async init() {
            const start = util.timeFormat(new Date(), 'yyyy-MM-dd') + ' ' + this.defaultTime[0];
            const end = util.timeFormat(new Date(new Date().getTime() + 3600 * 1000 * 24 * 1), 'yyyy-MM-dd') + ' ' + this.defaultTime[1];
            this.value1 = [
                start,
                end,
            ];
            this.getStation();
            this.getMrsjtj();
        },
        // 查询
        search() {
            this.getMrsjtj();
        },
        // 拉取场站
        async getStation() {
            const res = await this.getStationApi();
            if (res) {
                this.stations = res;
                this.stations.unshift({ id: '', name: '全部' });
            }
        },
        // 每日数据统计
        async getMrsjtj() {
            this.loading = true;
            const res = await this.getMrsjtjApi();
            if (res) {
                this.statistic = res;
                // 生产差方=调度派单量-生产方量
                // eslint-disable-next-line radix
                this.statistic.sccf = (Number(res.ddpdl) - Number(res.scfl)).toFixed(2);
                // 过磅差方=发货方量-出场过磅量
                // eslint-disable-next-line radix
                this.statistic.gbcf = (Number(res.fhfl) - Number(res.ccgbl)).toFixed(2);
                // 销售差方=销售方量-发货方量
                // eslint-disable-next-line radix
                this.statistic.xscf = (Number(res.xsfl) - Number(res.fhfl)).toFixed(2);
                // 签收差方=发货方量-客户签收方量
                this.statistic.qscf = (Number(res.fhfl) - Number(res.khqsfl)).toFixed(2);
            }
            this.loading = false;
        },
        // 数据钻取
        dataDrilling(tableCode, title) {
            this.$toast(
                {
                    title: true,
                    text: title + '详情',
                    type: 'eject',
                    t_url: 'tableList/index',
                    width: '15.6rem',
                    extr: {
                        code: {
                            TableCode: tableCode,
                            QueryString: 'station_id=' + this.station + '&start_time=' + this.value1[0] + '&end_time=' + this.value1[1],
                        },
                    },
                }
            );
        },

        // 显示数据弹框
        showDataInfo(tableCode, title) {
            this.$toast(
                {
                    title: true,
                    text: title + '详情',
                    type: 'eject',
                    t_url: 'statisticalAnalysis/salesStatistics/newDayDataInfo',
                    width: '15.6rem',
                    extr: {
                        code: tableCode,
                        QueryString: 'station_id=' + this.station + '&start_time=' + this.value1[0] + '&end_time=' + this.value1[1],
                    },
                }
            );
        },

        getMrsjtjApi() {
            const queryStr = '?station_id=' + this.station + '&start_time=' + this.value1[0] + '&end_time=' + this.value1[1];
            const path = '/interfaceApi/report/tjfx/mrsj/new/tjs' + queryStr;
            return this.$axios.$get(path, { defEx: true });
        },

        getStationApi() {
            const path = '/interfaceApi/report/salesanalysis/station';
            return this.$axios.$get(path, { defEx: true });
        },
    },
};
</script>
<style lang="stylus">
.dayData
    .wrapper
        height 100%
        background #fff
        padding .1rem
        .header-wrapper
            margin-top 0.1rem;
            margin-bottom .2rem
            .time
                margin 0 .1rem
                vertical-align middle
            .el-input__inner
                height 0.36rem!important;
            .search-btn
                width .9rem
                height .36rem
                background #2978ff
                border-radius .04rem
                display inline-block
                color #ffffff
                text-align center
                line-height .36rem
                margin-left .1rem
                cursor pointer
            span
                vertical-align middle
            .el-select
                vertical-align middle
        .main
            height calc(100% - .65rem)
            display flex
            padding-bottom: .1rem;
            .left
                float left
                margin-right .05rem
                text-align center
                height 100%
            .right
                float left
                text-align center
                width calc(100% - 8rem)
                height 100%
                display flex
                flex-direction column
            .big
                padding-top 40%
                width 3.9rem;
                height 100%;
            .row-wrpper
                flex 1
                display flex
                &.wrapper-small
                    max-height 1rem;
                &:last-child
                    .item
                        margin-bottom 0.01rem
                .item
                    margin-right 0.05rem
                    margin-bottom 0.05rem
                    flex 1
                    display flex
                    justify-content center
                    align-items center
                .col-wrapper
                    flex 1
                    display: flex;
                    flex-direction: column;
                    .item
                        flex 1
            .block-item
                box-sizing border-box
                background: linear-gradient(315deg, #55A7FE 0%, #3E79C5 100%);
                border-radius: 0.08rem;
                cursor pointer
                position relative
                &:hover
                    box-shadow .06rem .06rem 0.05rem 0 rgba(0,0,0,0.2)
            .child-item
                position absolute
                right 0
                bottom 0
                border 2px solid #fff
                display flex
                justify-content center
                align-items center
            .dgfont
                font-family technology
                font-size .68rem
                color #fff
                white-space nowrap
                text-overflow ellipsis
                overflow hidden
                padding 0 .1rem
                display flex
                justify-content center
                align-items center
            .discribe
                font-size .18rem
                color #fff
            .font30
                font-size .3rem
            .font40
                height 0.4rem
                font-size .4rem
            .font50
                height 0.5rem
                font-size .5rem
            .font60
                height 0.6rem
                font-size .6rem
            .font70
                height 0.7rem
                font-size .7rem
            .font80
                height 0.8rem
                font-size .8rem
            .block1
                background: linear-gradient(315deg, #EFAB54 0%, #FA8C47 100%);
            .block2,.block3,.block5,.block8
                background: linear-gradient(315deg, #55A7FE 0%, #3E79C5 100%);
            .block2-1
                width: 1.96rem;
                height: 1.12rem;
                background: #3573BE;
                border-radius: 0.08rem;
            .block4
                background: linear-gradient(315deg, #95D478 0%, #44BF5E 100%);
                justify-content flex-start!important
                > .bottom-position
                    padding-left 0.4rem;
            .block4-1
                width 1.36rem;
                height 0.74rem
                background: #3EA350;
            .block6,.block7
                justify-content flex-start!important
                padding-left 0.4rem
                background: linear-gradient(135deg, #DD775D 0%, #F19C75 100%);
            .block7-1
                width: 1.36rem;
                height: 90%
                background: #1F917F
                .dgfont
                    height 0.2rem
                    padding-bottom 0
                .discribe
                    font-size 0.16rem
            .block8
                justify-content flex-start!important
                padding-left 1.5rem
            .block9
                justify-content flex-start!important
                padding-left 1.5rem
            .block9-1
                width: 4.25rem;
                height: 1.15rem;
                background: #1F917F;
            .block10
                background: linear-gradient(90deg, #9A6ABC 0%, #C098E5 100%);
                justify-content flex-start!important
                padding-left 0.25rem
            .block10-1
                width: 1.45rem;
                height: 1rem;
                background: #945CBC;
            .block11
                background: linear-gradient(133deg, #399ABD 0%, #4BBDE6 100%);
            .block111
                color red

</style>
